import { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';
import { Address } from 'viem';
import { useAccount, useDisconnect } from 'wagmi';

import Button, { BUTTON_VARIANT } from '@components/button';
import UserAddress from '@components/userAddress';

import { WalletIcon } from '@shared/assets';
import { useConnectWallet } from '@shared/hooks';

interface IProps {
  name: string;
  email: string;
  address: Address;
}

export const truncateAddress = (
  input: string,
  first: number = 7,
  last: number = 6
): string => {
  const start = input.slice(0, first);
  const end = input.slice(input.length - last);

  return `${start}...${end}`;
};

export const ConnectButton = ({ className }: { className?: string }) => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { handleConnect, isReconnecting } = useConnectWallet();
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (!isHydrated || isReconnecting) {
    return null;
  }

  if (!address) {
    return (
      <button
        className={twMerge(
          'animatedBorderButton label-small roundedBlTr flexCenter relative z-[1] h-10 w-fit shrink-0 cursor-pointer overflow-hidden px-3 font-bold text-white',
          className
        )}
        onClick={handleConnect}
        disabled={isReconnecting}
      >
        <div className="flex items-center gap-2">
          <WalletIcon />
          Connect wallet
        </div>
      </button>
    );
  }

  return (
    <div
      className={twMerge(
        'flex w-fit items-center gap-2 border border-neutral700 px-2 py-1',
        className
      )}
    >
      <UserAddress
        address={truncateAddress(address as string) as `0x${string}`}
        className="mt-0 flex"
        hideCopy
      />
      <Button
        className="p-0 underline"
        variant={BUTTON_VARIANT.LINK}
        onClick={() => disconnect()}
      >
        Disconnect
      </Button>
    </div>
  );
};

const UserData = ({ email, name }: IProps) => {
  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <h2 className="font-xxlarge">{name}</h2>
        <p
          className="font-medium text-neutral200 no-underline"
          style={{
            textDecoration: 'none',
          }}
        >
          {email}
        </p>
      </div>
    </div>
  );
};

export default UserData;
