'use client';

import { twMerge } from 'tailwind-merge';
import { Address } from 'viem';

import CopyIcons from '@components/copyIcons';

import { useCopyText } from '@shared/hooks';

import { shortenAddress } from './helpers';

interface IProps {
  address?: Address;
  className?: string;
  hideCopy?: boolean;
}

const UserAddress = ({ address, className, hideCopy = false }: IProps) => {
  const { isCopied, copyAddressHandler } = useCopyText(address);

  const addressClassName = twMerge(
    'label-small sm:label-medium !leading-none',
    isCopied ? 'text-green500' : 'text-white'
  );

  return (
    <div
      className={twMerge(
        'mt-2 flex w-max items-center gap-2.5 bg-neutral700 p-2',
        className
      )}
    >
      <p
        style={{ textTransform: 'none' }}
        className={twMerge(addressClassName, 'max-xs:hidden')}
      >
        {address}
      </p>
      <p
        style={{ textTransform: 'none' }}
        className={twMerge(addressClassName, 'xs:hidden')}
      >
        {shortenAddress(address)}
      </p>
      {!hideCopy && (
        <CopyIcons
          className="size-4"
          isCopied={isCopied}
          copyAddressHandler={copyAddressHandler}
        />
      )}
    </div>
  );
};

export default UserAddress;
