import {
  withAuthenticatedClientRequest
} from '@api/requestBuilder/client/withClientRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';
import { generateUrlParams } from '@shared/utils/urlString';
import { GetSignMessageParams, GetSignMessageResponse, GetUserConnectWalletData } from '@interfaces/userConnectWallet';
import { ResponseWrapper } from '@interfaces';

const userConnectWallet =
  (request: ICallableRequestBuilder<ResponseWrapper<{ data: string }>>) => // temp response
    (data: GetUserConnectWalletData): Promise<ResponseWrapper<{ data: string }>> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/connect-wallet/connect`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            ...init.headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const getSigninMessage =
  (request: ICallableRequestBuilder<GetSignMessageResponse>) =>
    async (params: GetSignMessageParams) => {
      const paramString = generateUrlParams(params);
      return request.call(
        `${config.userApiUrl}/api/v1/user/connect-wallet/sign-message?${paramString}`
      );
    };

export const userConnectWalletApi = {
  client: {
    userConnectWallet: withAuthenticatedClientRequest(userConnectWallet),
    getSigninMessage: withAuthenticatedClientRequest(getSigninMessage),
  },
};

