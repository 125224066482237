// export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/;
export const EMAIL_REGEX =
  /^(?=.{1,64}@)(?!.*\.\.)[\w.-]+(\+[\w.-]+)?@([\w-]+\.)+[\w-]{2,4}$/;

export const USERNAME_REGEX = /^[a-zA-Z0-9@$!%*?&_#.-]{0,255}$/;

export const INTEGER_REGEX = /^[0-9]*$/;

export const THREE_DIGITS_TWO_DECIMALS_REGEX = /^\d{1,3}(\.\d{1,2})?$/;

export const AT_LEAST_ONE_UPPERCASE_LOWERCASE_DIGIT =
  /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;

export const AT_LEAST_ONE_SPECIAL_CHARACTER =
  /^(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+\-=|])/;

export const MIN_8_CHARACTERS = /^.{8,}$/;

export const UNSIGNED_FLOAT_REGEX = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;

export const URL_REGEX = /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6})|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[^\s]*)?$/;

export const ONLY_CHARACTERS_AND_NUMBERS_REGEX = /^[a-zA-Z0-9 ]+$/;

export const UUID_REGEX = /^[a-fA-F0-9-]{36}-/;

export const getNumberRegexWithDecimals = (decimalPlaces: number) => {
  return new RegExp(`^\\d+(\\.\\d{1,${decimalPlaces}})?$`);
};
