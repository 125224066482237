import { useMutation } from '@tanstack/react-query';
import { userConnectWalletApi } from '../userConnectWalletApi';
import { GetUserConnectWalletData } from '@interfaces/userConnectWallet';

interface MutationParams {
  onSuccess?: () => void;
}

const useUserConnectWalletMutation = ({
  onSuccess,
}: MutationParams) => {
  return useMutation({
    mutationFn: async (data: GetUserConnectWalletData) =>
      userConnectWalletApi.client.userConnectWallet(data),
    onSuccess,
  });
};

export default useUserConnectWalletMutation;
