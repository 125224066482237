'use client';

//LOGO
export { default as LogoSvg } from '@assets/icons/logo/logo.svg';
export { default as LogoHeadSvg } from '@assets/icons/logo/logo-head.svg';

export { default as BulletListIcon } from '@assets/icons/bullet-list.svg';
export { default as FileRefreshIcon } from '@assets/icons/file-refresh.svg';
export { default as UserIcon } from '@assets/icons/user.svg';

export { default as RedCloseIcon } from '@assets/icons/red-close.svg';
export { default as CloseIcon } from '@assets/icons/close-icon.svg';
export { default as CloseIcon2 } from '@assets/icons/close-icon-2.svg';
export { default as ChevronDownIcon } from '@assets/icons/chevron-down.svg';
export { default as ChevronLeft } from '@assets/icons/chevron-left.svg';
export { default as ChevronRightIcon } from '@assets/icons/chevron-right.svg';
export { default as CheckIcon1 } from '@assets/icons/check-icon-1.svg';
export { default as CheckIcon2 } from '@assets/icons/check-icon-2.svg';
export { default as CheckCircle } from '@assets/icons/check-circle.svg';

export { default as ClipboardClock } from '@assets/icons/clipboard-clock.svg';
export { default as CheckIcon3 } from '@assets/icons/check-icon-3.svg';
export { default as SearchIcon } from '@assets/icons/search-icon.svg';
export { default as PhotoIcon } from '@assets/icons/photo.svg';
export { default as RadioCheckedIcon } from '@assets/icons/radio-checked.svg';
export { default as RadioIcon } from '@assets/icons/radio.svg';
export { default as ErrorIcon } from '@assets/icons/error-icon.svg';
export { default as SubmissionIcon } from '@assets/icons/submission-icon.svg';
export { default as RemoveIcon } from '@assets/icons/remove-icon.svg';
export { default as RemoveIconCircled } from '@assets/icons/remove-icon-circled.svg';
export { default as SquareChartSettings } from '@assets/icons/square-chart-settings.svg';
export { default as EllipseIcon } from '@assets/icons/ellipse.svg';
export { default as DeleteIcon } from '@assets/icons/delete-icon.svg';
export { default as CloseCircle } from '@assets/icons/close-circle.svg';
export { default as Fireworks } from '@assets/icons/fireworks.svg';
export { default as CheckMark } from '@assets/icons/check-mark.svg';
export { default as ArrowShareIcon } from '@assets/icons/arrow-share-icon.svg';
export { default as ArrowTurnRight } from '@assets/icons/arrow-turn-right.svg';
export { default as DotIcon } from '@assets/icons/dot-icon.svg';
export { default as GreenToRedGradientIcon } from '@assets/icons/green-to-red-gradient-icon.svg';
export { default as SafeIcon } from '@assets/icons/safe-icon.svg';
export { default as UploadBoxIcon } from '@assets/icons/upload-icon-box.svg';
export { default as ChartIcon } from '@assets/icons/chart.svg';

export { default as FilterCheck } from '@assets/icons/filter-check.svg';
export { default as Enter } from '@assets/icons/enter.svg';

export { default as HistoryIcon } from '@assets/icons/history-icon.svg';
export { default as StockMarketIcon } from '@assets/icons/stock-market.svg';
export { default as ProductSubmissionIcon } from '@assets/icons/product-submission-icon.svg';
export { default as TeamIcon } from '@assets/icons/team-icon.svg';
export { default as TransactionIcon } from '@assets/icons/transaction-icon.svg';
export { default as BoxIcon } from '@assets/icons/box-icon.svg';
export { default as CoinIcon } from '@assets/icons/coin-icon.svg';
export { default as MoneyIcon } from '@assets/icons/money-icon.svg';
export { default as ArrowBackToUpRight } from '@assets/icons/arrow-back-to-up-right.svg';
export { default as ArrowBackToUpRight2 } from '@assets/icons/arrow-back-to-up-right-2.svg';
export { default as FeesIcon } from '@assets/icons/fees-icon.svg';
export { default as CubesIcon } from '@assets/icons/cubes-icon.svg';
export { default as Party } from '@assets/icons/party.svg';
export { default as Calendar } from '@assets/icons/calendar.svg';
export { default as Export } from '@assets/icons/export.svg';
export { default as EyeOpen } from '@assets/icons/eye-open.svg';
export { default as EyeOpenLargeIcon } from '@assets/icons/eye-open-large.svg';
export { default as EyeClose } from '@assets/icons/eye-closed.svg';
export { default as EyeCloseLargeIcon } from '@assets/icons/eye-closed-large.svg';
export { default as EyeCloseNoWidth } from '@assets/icons/eye-closed-no-width.svg';
export { default as Hidden } from '@assets/icons/hidden-icon.svg';
export { default as License } from '@assets/icons/license.svg';
export { default as Link } from '@assets/icons/link.svg';
export { default as Email } from '@assets/icons/email.svg';
export { default as Logout } from '@assets/icons/logout.svg';
export { default as ChangePassword } from '@assets/icons/carbon-password.svg';
export { default as SuitcaseWithClockIcon } from '@assets/icons/suitcase-with-clock-icon.svg';
export { default as StorageBoxIcon } from '@assets/icons/storage-box-icon.svg';
export { default as ClockIcon } from '@assets/icons/clock-icon.svg';
export { default as ChartSettings } from '@assets/icons/square-chart-settings.svg';
export { default as ClockIconDeposit } from '@assets/icons/clock-icon-deposit.svg';
export { default as DeleteBin } from '@assets/icons/delete-bin-icon.svg';

export { default as RatingsBadgeIcon } from '@assets/icons/ratings-badge-icon.svg';
export { default as MenuIcon } from '@assets/icons/menu-icon.svg';
export { default as WalletIcon } from '@assets/icons/wallet-icon.svg';

export { default as AddIcon } from '@assets/icons/add-icon.svg';
export { default as FullScreenIcon } from '@assets/icons/full-screen.svg';
export { default as RefreshIcon } from '@assets/icons/refresh-icon.svg';
export { default as StarIcon } from '@assets/icons/star-icon.svg';
export { default as StarFullIcon } from '@assets/icons/star-full.svg';
export { default as StarHalfIcon } from '@assets/icons/star-half-icon.svg';

export { default as HorizontalDotsIcon } from '@assets/icons/horizontal-dots-icon.svg';
export { default as CopyIcon } from '@assets/icons/copy-icon.svg';
export { default as LaunchAppIcon } from '@assets/icons/launch-app-icon.svg';
export { default as VeriffLogo } from '@assets/icons/socialIcons/veriff.svg';
export { default as HeartIcon } from '@assets/icons/heart-icon.svg';
export { default as ReplyIcon } from '@assets/icons/reply-icon.svg';
export { default as HumanBubbleIcon } from '@assets/icons/human-bubble-icon.svg';

export { default as GoogleIcon } from '@assets/icons/google-icon.svg';

export * from './profile';
export * from './products';
export * from './socialIcons';
export * from './files';
export * from './approval';
export * from './publisher';
export * from './forum';
export * from './buyAitech';

//toast icons
export { default as SuccessIcon } from '@assets/icons/toast/success-icon.svg';
export { default as WarningIcon } from '@assets/icons/toast/warning-icon.svg';
export { default as InfoIcon } from '@assets/icons/toast/info-icon.svg';
export { default as ErrorToastIcon } from '@assets/icons/toast/error-icon.svg';
export { default as GripDotsVerticalIcon } from '@assets/icons/grip-dots-vertical.svg';

export { default as HandHoldingCoinIcon } from '@assets/icons/hand-holding-coin.svg';
export { default as VaultIcon } from '@assets/icons/vault.svg';
export { default as ConvertIcon } from '@assets/icons/convert.svg';
export { default as CheckCircleIcon } from '@assets/icons/check-cicrcle-icon.svg';
export { default as ReceiptLogo } from '@assets/icons/receipt-logo.svg';
export { default as FileIcon } from '@assets/icons/file-icon.svg';
export { default as Box3dIcon } from '@assets/icons/box-3d.svg';
export { default as AvaChatIcon } from '@assets/icons/ava-chat.svg';

export { default as UFOIcon } from '@assets/icons/ufo.svg';

//Maintenance
export { default as RocketIcon } from '@assets/icons/rocket.svg';
