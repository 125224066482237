export const createStartingValueForCounter = (endValue: string) => {
  if (endValue.includes('.')) {
    const [digitsBeforePoint, digitsAfterPoint] = endValue.split('.');
    const digitsAfterPointWithoutK = digitsAfterPoint.replace('k', '');
    return `${'0'.repeat(digitsBeforePoint.length)}.${'0'.repeat(digitsAfterPointWithoutK.length)}k`;
  }

  const digitsBeforeK = endValue.split('k')[0].replace('k', '');
  return `${'0'.repeat(digitsBeforeK.length)}k`;
};

export const tryParseJsonString = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return null;
  }
};

const formatNumberWithTwoDecimalPlaces = (number: number) => {
  if (!number) return number;

  const roundedNumber = number.toFixed(2);
  const integerPart = parseInt(number.toString());

  return integerPart === number ? integerPart : parseFloat(roundedNumber);
};

export const formatLocaleString = (value: number) => {
  return formatNumberWithTwoDecimalPlaces(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });
};

export const normalizeFileName = (fileName: string) => {
  const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
  const normalized = fileName
    .replace(invalidChars, '')
    .trim()
    .replace(/\s+/g, '_');

  if (normalized.length === 0) {
    return 'default_file_name';
  }

  return normalized;
};
