import { WarningIcon } from '@shared/assets';

const InsufficientBalanceWarning = () => {
  return (
    <div className="my-4 flex justify-end">
      <div className="flex h-9 w-full items-center gap-[10px] bg-yellow900 p-2 text-yellow500 md:w-1/4 xl:w-1/6">
        <div className="min-w-[18px]">
          <WarningIcon className="h-4 w-[18px]" />
        </div>
        <p className="label-large">Balance is low</p>
      </div>
    </div>
  );
};

export default InsufficientBalanceWarning;
