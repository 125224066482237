export enum BUTTON_VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  PRIMARY_OUTLINED = 'primaryOutlined',
}

export const BUTTON_VARIANT_CLASSNAME = {
  [BUTTON_VARIANT.PRIMARY]:
    'bg-green500 label-small text-neutral900 hover:bg-green600 active:bg-green700 disabled:bg-neutral700 disabled:text-neutral800 uppercase',
  [BUTTON_VARIANT.SECONDARY]:
    'bg-transparent border text-white border-solid border-neutral600 hover:border-neutral400 active:border-neutral100 disabled:bg-neutral700 disabled:text-neutral800 uppercase',
  [BUTTON_VARIANT.LINK]:
    'bg-transparent uppercase text-yellow500 active:text-yellow600 disabled:text-neutral700',
  [BUTTON_VARIANT.PRIMARY_OUTLINED]:
    'bg-transparent border border-solid border-green500 text-green500 hover:bg-green500 hover:text-neutral900 active:bg-green700 active:text-neutral900 disabled:bg-neutral700 disabled:text-neutral900 uppercase',
};

export enum BUTTON_SIZE {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export const BUTTON_SIZE_CLASSNAME = {
  [BUTTON_SIZE.BIG]: 'py-3 px-4 gap-2 rounded-bl-md rounded-tr-md',
  [BUTTON_SIZE.MEDIUM]: 'py-2.5 px-3 gap-2.5 rounded-bl-md rounded-tr-md',
  [BUTTON_SIZE.SMALL]: 'py-1 px-2 gap-2 rounded-sm rounded-sm',
};
