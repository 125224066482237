import { useCallback, useEffect, useState } from 'react';

import useAuthenticatedUserSocket from '../useAuthenticatedUserSocket';
import { IDepositSocketResponse } from '@interfaces';
import { toast } from 'react-toastify';
import { PROFILE_KEYS } from '@shared/constants';
import { useQueryClient } from '@tanstack/react-query';
import { useDepositModal } from '@features/profile/layout/profileStats/depositFundsModal/provider/context';

interface IWarningBalanceResponse {

}

const useReadUserBalanceEvent = () => {

  const { socket } = useAuthenticatedUserSocket();

  const queryClient = useQueryClient();

  const { triggerSuccessModal } = useDepositModal();
  const [isActive, setIsActive] = useState(false);

  const handleDepositMessage = useCallback(
    (data: IDepositSocketResponse) => {
      if (data) {
        queryClient.invalidateQueries({ // for 2 case: deposit and warning balance
          queryKey: PROFILE_KEYS.PURCHASE_INFO,
        });

        if (data.tx && data.aitechAmount && data.usdAIAmount) {
          toast.dismiss('deposit-funds');
          if (isActive) {
            triggerSuccessModal({
              depositAmount: Number(data.aitechAmount)
            })
          }
        }
      }
    },
    [queryClient, triggerSuccessModal, isActive]
  );

  useEffect(() => {
    socket?.on('deposited', handleDepositMessage);

    return () => {
      socket?.off('deposited', handleDepositMessage);
    };
  }, [socket, handleDepositMessage]);


  useEffect(() => {
    if (document.visibilityState === "visible") {
      setIsActive(true);
    } else if (document.visibilityState === "hidden") {
      setIsActive(false);
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setIsActive]);

  const handleUpdateShowWarning = useCallback(
    (data: IWarningBalanceResponse) => {
      if (data) {
        queryClient.invalidateQueries({
          queryKey: PROFILE_KEYS.PURCHASE_INFO,
        });
      }
    },
    [queryClient]
  );

  useEffect(() => {
    socket?.on('warning-low-balance', handleUpdateShowWarning);

    return () => {
      socket?.off('warning-low-balance', handleUpdateShowWarning);
    };
  }, [socket, handleUpdateShowWarning]);

};

export default useReadUserBalanceEvent;
